<template>
  <div>
    <HeroText title="Virtual" subtitle="Tour" />
  </div>
</template>

<script>
import HeroText from '@/atoms/HeroText'

export default {
  name: 'VirtualTourPage',
  components: { HeroText },
  mounted () {
    this.$root.preloader.visible = false
  }
}
</script>

<style lang="scss" scoped>

</style>
